import { message } from 'antd'
import axios from 'axios'
import { createContext, useState } from 'react'
import { useQueryClient } from 'react-query'

import { API_URL } from '../constants'

export const OptInContext = createContext(null)

export const OptInContextProvider = props => {
  const queryClient = new useQueryClient()
  const [optIn, setOptIn] = useState()
  const [formData, setFormData] = useState({})

  const completeStep = async (nextStep, optInId, campaignStepId, data) => {
    const { shoutoutData, giveawayWinners } = data
    const stepData = {
      optInId,
      nextStep,
      optInStep: {
        completed: new Date(),
        shoutoutData,
        optInId,
        campaignStepId,
      },
      giveawayWinners,
    }
    try {
      const res = await axios.post(`${API_URL}/steps`, stepData)
      queryClient.invalidateQueries('opt-in')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const stepAction = async (optInId, campaignStepId, data = {}) => {
    let customForm
    if (data.customForm && Object.keys(data.customForm).length !== 0) {
      customForm = Object.keys(data.customForm).map(key => {
        return { uuid: key, value: data.customForm[key] }
      })
    }
    try {
      const res = await axios.post(`${API_URL}/opt-in/${optInId}/step/${campaignStepId}`, {
        ...data,
        customForm,
      })
      queryClient.invalidateQueries('opt-in')
      queryClient.invalidateQueries('campaignSteps')
      message.success('Step updated')
      return res.data
    } catch (err) {
      return { error: 'Oops, something went wrong.' }
    }
  }

  const OptInContextValues = {
    optIn,
    setOptIn,
    formData,
    setFormData,
    completeStep,
    stepAction,
  }

  return <OptInContext.Provider value={OptInContextValues}>{props.children}</OptInContext.Provider>
}
