import { useEffect } from 'react'

export const useMetaData = (title, description, image) => {
  useEffect(() => {
    if (title) {
      document.title = title
      document.querySelector('[property="og:title"]').setAttribute('content', title)
      document.querySelector('[name="twitter:title"]').setAttribute('content', title)
    }
    if (description) {
      document.querySelector('[name="description"]').setAttribute('content', description)
      document.querySelector('[property="og:description"]').setAttribute('content', description)
      document.querySelector('[name="twitter:description"]').setAttribute('content', description)
    }
    if (image) {
      document.querySelector('[property="og:image"]').setAttribute('content', image)
      document.querySelector('[name="twitter:image"]').setAttribute('content', image)
    }
  })
}
