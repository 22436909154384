import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Alert, Button, Carousel } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import React, { useContext } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'
import Cookies from 'universal-cookie'
import en from 'world_countries_lists/data/countries/en/world.json'
import * as yup from 'yup'

import hashtag from '../../../assets/images/login/hashtag.svg'
// CAROUSEL IMAGES
import creator1 from '../../../assets/images/signup/creator1.png'
import creator2 from '../../../assets/images/signup/creator2.png'
import creator3 from '../../../assets/images/signup/creator3.png'
import creator4 from '../../../assets/images/signup/creator4.png'
import creator5 from '../../../assets/images/signup/creator5.png'
import image1 from '../../../assets/images/signup/image1.png'
import image2 from '../../../assets/images/signup/image2.png'
import image3 from '../../../assets/images/signup/image3.png'
import image4 from '../../../assets/images/signup/image4.png'
import image5 from '../../../assets/images/signup/image5.png'
import logo1 from '../../../assets/images/signup/logo1.png'
import logo2 from '../../../assets/images/signup/logo2.png'
import logo3 from '../../../assets/images/signup/logo3.png'
import logo4 from '../../../assets/images/signup/logo4.png'
import logo5 from '../../../assets/images/signup/logo5.png'
import { ImageLoader } from '../../../components/general/ImageLoader'
import { UserContext } from '../../../contexts/UserContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'
import { FormItem } from '../../general/forms/FormItem'
import { Wrapper } from '../Login'

export const Signup = () => {
  useMetaData(
    'Sign up | Creator',
    'Create an account to start collaborating with brands.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const navigate = useNavigate()
  const cookies = new Cookies()

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    code: 1,
    short: 'US',
    utms: {
      utmCampaign: cookies.get('utm_campaign') || '',
      utmMedium: cookies.get('utm_medium') || '',
      utmSource: cookies.get('utm_source') || '',
      utmContent: cookies.get('utm_content') || '',
      utmTerm: cookies.get('utm_term') || '',
    },
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required').min(2, 'Please enter at least 2 characters'),
    lastName: yup.string().required('Required').min(2, 'Please enter at least 2 characters'),
    phone: yup
      .string()
      .required('Required')
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\./0-9]*$/, 'Please enter a valid phone number')
      .min(7, 'Please enter a valid phone number'),
    email: yup.string().email('Please enter a valid email address').required('Required'),
    password: yup.string().required('Required').min(8, 'Minimum 8 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm your password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const { signupHandler } = useContext(UserContext)

  const carouselContent = [
    {
      logo: logo1,
      image: image1,
      subtext: 'Paid partnership with Bananagrams',
      caption: 'There is nothing better than cozy picnic with your family and bananagrams 🍌',
      creator: {
        avatar: creator1,
        username: '@tsova_yan',
      },
    },
    {
      logo: logo2,
      image: image2,
      subtext: 'Paid partnership with Essential Sports Nutrition',
      caption: `Today’s workout sponsored by Essential Sports Nutrition 💥😍💪🏼`,
      creator: {
        avatar: creator2,
        username: '@searching_for_sunshine',
      },
    },
    {
      logo: logo3,
      image: image3,
      subtext: 'Paid partnership with FreeYumm',
      caption:
        'Loved making my first charcuterie board with these @freeyummfoods #glutenfree #dairyfree snacks! ✨🥂',
      creator: {
        avatar: creator3,
        username: '@jjoyw3',
      },
    },
    {
      logo: logo4,
      image: image4,
      subtext: 'Paid partnership with Fresh! Meal Plan',
      caption:
        'If you know me, you know I love EASY healthy. Which is why Fresh Meal Plan is my absolute favorite meal delivery service 🙌🏻',
      creator: {
        avatar: creator4,
        username: '@brookegriffin.fit',
      },
    },
    {
      logo: logo5,
      image: image5,
      subtext: `Paid partnership with Nebula Grass`,
      caption: `We as a family are loving these Plush-600 series towels by @nebulagrass! Super approved by my little one 😍`,
      creator: {
        avatar: creator5,
        username: '@angelarasile',
      },
    },
  ]

  return !localStorage.getItem('crcoToken') ? (
    <Wrapper className='signup'>
      <div className='inner'>
        <section className='carousel'>
          <Carousel autoplay>
            {carouselContent.map((item, index) => (
              <div className='testimonial-card' key={index}>
                <img className='logo' src={item.logo} alt={`Wyder's Cider`} />
                <ImageLoader src={item.image} alt='' />
                <div className='text'>
                  <p className='subtext'>{item.subtext}</p>
                  <p className='caption'>{item.caption}</p>
                  <div className='creator-info'>
                    <div className='avatar'>
                      <img src={item.creator.avatar} alt='' />
                    </div>
                    {item.creator.username}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </section>
        <section className='login-form-container'>
          <Formik
            initialValues={initialValues}
            onSubmit={async (data, { setStatus }) => {
              setStatus('')
              const result = await signupHandler(data)
              if (result.error) {
                setStatus(result.error)
              } else {
                //if utm cookies are present, delete them
                if (cookies.get('utm_campaign')) cookies.remove('utm_campaign')
                if (cookies.get('utm_medium')) cookies.remove('utm_medium')
                if (cookies.get('utm_source')) cookies.remove('utm_source')
                if (cookies.get('utm_content')) cookies.remove('utm_content')
                if (cookies.get('utm_term')) cookies.remove('utm_term')
                navigate('/welcome')
              }
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}>
            {({ values, setValues, status, isSubmitting }) => (
              <Form>
                <img src={hashtag} alt='' className='hashtag' />
                <h1>Welcome to Creator.co!</h1>
                <p className='subtext'>Let&apos;s get you started.</p>
                <div className='form-row'>
                  <FormItem name='firstName'>
                    <Input name='firstName' placeholder='First Name' data-1p-ignore />
                  </FormItem>
                  <FormItem name='lastName'>
                    <Input name='lastName' placeholder='Last Name' data-1p-ignore />
                  </FormItem>
                </div>
                <ConfigProvider locale={en}>
                  <FormItem name='phone'>
                    <CountryPhoneInput
                      inline // fixes issue with dropdown not sticking
                      autoComplete='phone'
                      name='phone'
                      placeholder='Phone Number'
                      value={values}
                      onChange={e => setValues({ ...values, ...e })}
                      data-1p-ignore
                    />
                  </FormItem>
                </ConfigProvider>
                <FormItem name='email'>
                  <Input name='email' placeholder='Email Address' data-1p-ignore />
                </FormItem>
                <FormItem name='password'>
                  <Input.Password
                    name='password'
                    placeholder='Password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    autoComplete='new-password'
                    data-1p-ignore
                  />
                </FormItem>
                <FormItem name='confirmPassword'>
                  <Input.Password
                    name='confirmPassword'
                    placeholder='Confirm password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    autoComplete='new-password'
                    data-1p-ignore
                  />
                </FormItem>
                {status && <Alert message={status} type='error' showIcon />}
                <div className='buttons'>
                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Create Account
                  </Button>
                </div>
                <div className='sub-links'>
                  Already have an account? <Link to='/login'>Log In</Link>
                </div>
              </Form>
            )}
          </Formik>
          <div className='sub-links brand'>
            Are you a Brand? <a href='https://brands.creator.co/signup'>Sign Up Here</a>
          </div>
        </section>
      </div>
    </Wrapper>
  ) : (
    <Navigate to='/my-dashboard' />
  )
}
