import React, { useState } from 'react'
import styled from 'styled-components/macro'

export const ImageLoader = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [validUrl, setValidUrl] = useState(src)

  return (
    <Wrapper>
      {validUrl ? (
        <img
          src={src}
          alt={alt}
          className={`smooth-image ${imageLoaded ? 'visible' : 'hidden'}`}
          onLoad={() => setImageLoaded(true)}
          onError={() => setValidUrl(false)}
        />
      ) : (
        <div className='placeholder' />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .smooth-image {
    transition: opacity 0.3s ease-in-out;
  }
  .hidden {
    opacity: 0;
  }
  .visible {
    opacity: 1;
  }
  .placeholder {
    background: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
  }
`
