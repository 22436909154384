import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import LoadingHeart from './components/general/LoadingHeart'
import { Layout } from './components/screens/dashboard/Layout'
import { UserContext } from './contexts/UserContext'

export const Base = () => {
  const { isLoggedIn, fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('user', fetchCurrentUser)
  const location = useLocation()

  if (!isLoggedIn && location.pathname !== '/login') {
    return <Navigate to='login' />
  }

  return (
    <Wrapper>
      {isLoggedIn && userStatus === 'success' ? (
        <Layout userData={userData}>
          <Outlet />
        </Layout>
      ) : (
        (userStatus === 'loading' || !userData) && (
          <div className='loading'>
            <LoadingHeart />
          </div>
        )
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .loading {
    display: flex;
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
