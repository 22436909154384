import React from 'react'
import styled, { keyframes } from 'styled-components'

import logoIcon from '../../assets/images/primary-logo-icon.svg'
import logoText from '../../assets/images/primary-logo-text.svg'

const LoadingHeart = () => {
  return (
    <Wrapper>
      <span className='heart-wrapper'>
        <img className='heart' src={logoIcon} alt='icon' />
      </span>
    </Wrapper>
  )
}

const beatAnimation = keyframes`
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.2);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
`

const Wrapper = styled.div`
  background: #f4f4f8;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${logoText});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200px 200px;
  padding-left: 10px;

  .heart-wrapper {
    margin-right: -2px;
    margin-left: -2px;
  }

  .heart {
    width: 50px;
    animation: ${beatAnimation} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`

export default LoadingHeart
