import axios from 'axios'

axios.interceptors.request.use(
  function (config) {
    const crcoToken = localStorage.getItem('crcoToken')

    if (crcoToken != null) {
      config.headers.Authorization = `Bearer ${crcoToken}`
    } else {
      config.headers.Authorization = undefined
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

//After request is made
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
