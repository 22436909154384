import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import DashboardSidebar from './DashboardSidebar'

export const Layout = ({ children, userData }) => {
  const location = useLocation()

  const routesWithoutSidebar = ['/collaboration-hub', '/campaign', '/campaigns']
  const hideSidebar = routesWithoutSidebar.some(route => location.pathname.startsWith(route))

  return (
    <Wrapper>
      <>
        {!hideSidebar && <DashboardSidebar userData={userData} />}
        <main>{children}</main>
      </>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex: 1;
  main {
    flex: 1;
    .heading {
      background: linear-gradient(
        to right,
        ${props => props.theme.crcoCottonCandyBlue},
        ${props => props.theme.crcoCottonCandyPink}
      );
      padding: 10px 20px;
      text-align: center;
      z-index: 1;
      h1 {
        font-size: 1.2rem;
        margin: 0;
      }
      @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
        padding: 20px 30px;
        h1 {
          font-size: 1.4rem;
        }
      }
      @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
        padding: 30px 40px;
        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
`
