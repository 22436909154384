import { Spin } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import success from '../../../assets/images/icons/success.svg'
import { UserContext } from '../../../contexts/UserContext'
import { useMetaData } from '../../../custom-hooks/useMetaData'

export const Welcome = () => {
  useMetaData(
    'Welcome! | Creator Dashboard',
    'Thank you for signing up.',
    'https://creatorco.nyc3.cdn.digitaloceanspaces.com/assets/preview-images/meta-preview.png'
  )

  const { fetchCurrentUser } = useContext(UserContext)
  const { data: userData } = useQuery('user', fetchCurrentUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      setTimeout(() => {
        navigate('/collaboration-hub')
      }, 1000)
    }
  })

  return localStorage.getItem('crcoToken') ? (
    <Wrapper>
      <div className='inner'>
        <img src={success} alt='' />
        <h1>Thanks for signing up!</h1>
        <p>You&apos;ll be redirected to the Collaboration Hub shortly.</p>
        <Spin />
      </div>
    </Wrapper>
  ) : (
    <Navigate to='/collaboration-hub' />
  )
}

const Wrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: ${props => props.theme.crcoBoxShadow};
  overflow: hidden;
  width: 90vw;
  margin: auto;
  padding: 40px 20px;
  .inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    text-align: center;
    img {
      height: 50px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 500px) {
    width: 400px;
  }
`
