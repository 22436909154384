import { Empty } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const NotificationMenu = ({ notifications, setNotificationsOpen }) => {
  const Notification = ({ notification }) => {
    // Format the date and calculate the relative time
    const formattedDate = moment(notification.date).format('lll')
    const fromNow = moment(notification.date).fromNow()

    return (
      <Link
        className='notification'
        to={notification.link}
        onClick={() => setNotificationsOpen(false)}>
        <div className='notif-header'>
          <p className='title'>{notification.title}</p>
          <span className='badge' />
        </div>

        <p className='message'>{notification.message}</p>

        {notification.date && (
          <div className='date'>
            <span>{formattedDate}</span>
            <span>({fromNow})</span>
          </div>
        )}
      </Link>
    )
  }
  return (
    <Wrapper>
      {notifications?.length ? (
        // TODO: add notification type filter / tags? mark all as read? (would need a Notifications table in the backend for tracking)
        notifications.map((n, i) => <Notification key={i} notification={n} />)
      ) : (
        <Empty description={`You're all caught up!`} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .notification {
    padding: 10px;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
    }
    .notif-header {
      display: flex;
      justify-content: space-between;
    }
    .badge {
      width: 12px;
      height: 12px;
      background-color: ${props => props.theme.crcoCoral};
      border-radius: 50%;
      border: 2px solid white;
    }
    p {
      margin: 0;
    }
    .title {
      font-size: 16px;
      color: #23262f;
    }
    .message {
      font-size: 14px;
      color: ${props => props.theme.crcoGrey};
    }
    .date {
      font-size: 12px;
      color: ${props => props.theme.crcoGrey};
      margin-top: 5px;
      margin-left: auto;
      opacity: 0.6;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`
