import { ExclamationCircleOutlined } from '@ant-design/icons'
import React from 'react'

export const ErrorMessage = ({ msg }) => {
  return (
    <div
      style={{ color: 'red', display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}
      data-cy='form-error-text'>
      <ExclamationCircleOutlined style={{ marginRight: '8px', marginBottom: '3px' }} />
      <span>{msg}</span>
    </div>
  )
}
