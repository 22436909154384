import { createContext, useState } from 'react'

export const CampaignContext = createContext(null)

export const CampaignContextProvider = props => {
  const [formData, setFormData] = useState({ sort: 'date-desc', available: true })

  const CampaignContextValues = {
    formData,
    setFormData,
  }

  return (
    <CampaignContext.Provider value={CampaignContextValues}>
      {props.children}
    </CampaignContext.Provider>
  )
}
